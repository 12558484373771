






































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { SetTheme, InsurerPortalTheme } from '@/common/themes'
import Environment, { EnvironmentMode, getEnvMode } from '@/common/environment'
import Confirmation from '@/components/Confirmation.vue'
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal.vue'
import CookieBanner from '@/components/cookie/CookieBanner.vue'
import { cookieService } from './services/cookie-service'
import { IClientTemplate } from './models/client/interfaces/client-template'
import ClientTemplate from './models/client/client-template'
import ScriptTagSettings from './models/cookies/settings/script-tag-settings'
import ErrorSnackBar from './models/generic/error-snackbar'
import Shared from '@/common/shared'

@Component({
  components: {
    Confirmation,
    CookieBanner,
    PrivacyPolicyModal,
  },
})
export default class App extends Vue {
  @Prop()
  public clientSettings: IClientTemplate
  public Vuebar = require('vuebar')
  private showDefaultCookieBanner = false
  public legalNoticeExpanded = false
  private sanitizeHTML = Shared.sanitizeHTML

  public mounted() {
    SetTheme(this, new InsurerPortalTheme())
    this.setErrorColor()
    this.setInputTextColor()
  }

  private async created() {
    await this.$store.dispatch(
      'clientModule/submitClientTemplate',
      this.clientSettings
    )

    window.addEventListener('message', this.messageHandler, false)

    await this.$store.dispatch('generalModule/submitRetrieveEnvironmentSecrets')

    this.$store.dispatch(
      'clientModule/submitIsPreviewMode',
      this.$route.path.includes('/preview')
    )

    if (this.clientSettings.templateCookieRecord) {
      await this.setCookieBanner()
    }

    Vue.use(this.Vuebar)
    Vue.use(VueReCaptcha, {
      siteKey: this.environment.reCaptchaClientKey,
      loaderOptions: {
        autoHideBadge: true,
      },
    })

    const faviconUrl: string =
      this.clientTemplate.favIconURL !== ''
        ? this.clientTemplate.favIconURL
        : `${this.imageBaseUrl}/favicon.png`
    const link: any = document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = faviconUrl
    document.getElementsByTagName('head')[0].appendChild(link)
    document.title = this.clientTemplate.displayName

    // set map attribute
    const mapScript = document.createElement('script')
    mapScript.setAttribute(
      'src',
      'https://maps.googleapis.com/maps/api/js?key=' +
        this.environment.googleMapsApiKey +
        '&libraries=places,drawing,geometry'
    )
    if (document && document.head) {
      document.head.appendChild(mapScript)
    }
  }

  private get cookieNoticePath(): string {
    return this.$route.path.includes('/cookienotice') ? '' : 'cookienotice'
  }

  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private messageHandler(e: MessageEvent): void {
    const hostMatches = e.origin
      .toLowerCase()
      .startsWith(this.environment.editHostUrl.toLowerCase())

    if (hostMatches === false) {
      const isDev = getEnvMode(this.$store) <= EnvironmentMode.Development
      if (isDev === false) {
        return
      }
    }

    const message = e.data
    if (!message) {
      return
    }

    switch (message.command) {
      case 'UpdateSetting':
        if (message.data) {
          // update clientTemplate
          this.$store.dispatch('clientModule/submitUpdateTemplateSetting', {
            name: message.data.name,
            value: message.data.value,
          })
        }
        break
    }
  }

  private get getErrorColor(): string {
    return this.clientTemplate.errorColor
  }

  @Watch('getErrorColor')
  private onErrorColorChange() {
    this.setErrorColor()
  }

  private setErrorColor() {
    // set configured error color for error-messages
    const styleElement = document.getElementById('errorColor')
    if (styleElement) {
      document.getElementsByTagName('head')[0].removeChild(styleElement)
    }
    const style = document.createElement('style')
    style.id = 'errorColor'
    style.type = 'text/css'
    const errorColor = this.clientTemplate.errorColor
    style.innerHTML =
      '.v-input__control .error--text, .v-input.error--text{color:' +
      errorColor +
      '!important;' +
      'caret-color:' +
      errorColor +
      '!important;}'
    document.getElementsByTagName('head')[0].appendChild(style)
  }

  private get getInputTextColor(): string {
    return this.clientTemplate.inputTextColor
  }

  @Watch('getInputTextColor')
  private onInputTextColorChange() {
    this.setInputTextColor()
  }

  private showPrivacyPolicyDetails(): void {
    this.$store.dispatch('cookieModule/showPrivacyPolicyDetails', true)
  }

  private setInputTextColor() {
    // set configured input text color
    const inputStyleElement = document.getElementById('inputTextColor')
    if (inputStyleElement) {
      document.getElementsByTagName('head')[0].removeChild(inputStyleElement)
    }
    const style = document.createElement('style')
    style.id = 'inputTextColor'
    style.type = 'text/css'
    const inputTextColor = this.getInputTextColor
    style.innerHTML =
      '.v-input__control .v-input__slot .v-text-field__slot, .v-input input{color:' +
      inputTextColor +
      '!important;} .v-input__control .v-input__slot .v-select__slot .v-select__selections, .v-select__selection--comma{color:' +
      inputTextColor +
      '!important;} .v-input__control .v-input__slot .v-text-field__slot, textarea {color:' +
      inputTextColor +
      '!important;}'
    document.getElementsByTagName('head')[0].appendChild(style)
  }

  private addCookieScripts() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { clearDup, reloadOTBanner, getCookie } = require('@/common/cookies')

    this.clientTemplate.templateCookieRecord.scripts.forEach((script) =>
      this.addScript(script, true)
    )
    ;[clearDup, reloadOTBanner, getCookie]
      .map((script: string) => ({ body: script } as ScriptTagSettings))
      .forEach((script) => this.addScript(script))
  }

  private addScript(settings: ScriptTagSettings, head = false) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = false
    script.defer = true

    if (settings.source) {
      script.src = settings.source
    }

    if (settings.body) {
      script.innerHTML = settings.body
    }

    if (settings.attributes) {
      settings.attributes.forEach((att) => {
        script.setAttribute(att.key, att.value)
      })
    }

    document[head ? 'head' : 'body'].appendChild(script)
  }

  private get useDefaultCookieBanner(): boolean {
    return this.$store.getters['cookieModule/useDefaultCookieBanner']
  }

  private async setCookieBanner() {
    await this.$store.dispatch('cookieModule/retrieveCookieRecord')

    if (this.useDefaultCookieBanner) {
      this.$store.dispatch(
        'cookieModule/setDefaultCookieSettings',
        cookieService.retrieveDefaultSettings()
      )
      this.showDefaultCookieBanner = true
    } else {
      this.addCookieScripts()
    }
  }

  private get errorSnackbarConfig(): ErrorSnackBar {
    return this.$store.getters['generalModule/errorSnackBar']
  }

  private set errorSnackbarConfig(config: ErrorSnackBar) {
    this.$store.dispatch('generalModule/submitSnackBar', config)
  }

  private get imageBaseUrl(): string {
    return `${process.env.VUE_APP_STORAGE_URL}/releases/${this.clientTemplate.portalVersion}/img`
  }
}
