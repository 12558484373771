import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import ClientTemplate from '@/models/client/client-template'

const defaultContext = {
  git: {
    repository_url: process.env.VUE_APP_REPOSITORY_URL,
    commit: {
      sha: process.env.VUE_APP_GIT_COMMIT_SHA,
    },
  },
}

export function startDatadog() {
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  const site = 'datadoghq.eu'
  const clientToken = process.env.VUE_APP_DD_CLIENT_TOKEN || ''
  const service = 'simplifi.customerportals.app'
  const env = process.env.VUE_APP_DD_ENVIRONMENT_NAME || 'development'

  // Specify a version number to identify the deployed version of your application in Datadog
  const version = process.env.VUE_APP_DD_CI_BUILD_ID

  datadogRum.init({
    applicationId: process.env.VUE_APP_DD_APPLICATION_ID || '',
    clientToken,
    site,
    service,
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [process.env.VUE_APP_API_BASE_URL || ''],
  })

  datadogLogs.init({
    clientToken,
    site,
    service,
    env,
    version,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: 'all',
  })

  datadogRum.setGlobalContext(defaultContext)
  datadogLogs.setGlobalContext(defaultContext)
}

export function onClientTemplateChanged(clientTemplate: ClientTemplate) {
  const context = {
    simplifi: {
      portal: {
        id: clientTemplate.subDomain,
        name: clientTemplate.displayName,
      },
    },
    ...defaultContext,
  }
  datadogRum.setGlobalContext(context)
  datadogLogs.setGlobalContext(context)
}
