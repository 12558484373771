import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '../public/assets/css/style.css'
import '@mdi/font/css/materialdesignicons.css'
import 'babel-polyfill'
import Vuebar from 'vuebar'
import '@/validation/validation-config'
import { ValidationProvider } from 'vee-validate'
import { defaultClientTemplate } from './common/default-client-template'
import ldUserPlugin from './plugins/ld-user-plugin'
import { startDatadog } from './plugins/datadog'

startDatadog()

Vue.component('ValidationProvider', ValidationProvider)
Vue.use(Vuetify)
Vue.use(Vuebar)

Vue.config.productionTip = false

const vuetifyConfig = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
})

Vue.use(ldUserPlugin, {
  envKey: process.env.VUE_APP_LD_CLIENT_ID,
  store,
  ldOptions: { streaming: true },
})

// App loaded (Standalone)
if (process.env.VUE_APP_RUN_STANDALONE) {
  new Vue({
    router,
    store,
    vuetify: vuetifyConfig,
    render: (h) => h(App, { props: { clientSettings: defaultClientTemplate } }),
  }).$mount('#app')
} else {
  // App loaded via customer portal router
  new Vue({
    router,
    store,
    vuetify: vuetifyConfig,
    components: {
      app: App,
    },
  }).$mount('#app')
}
